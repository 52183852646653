@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,700;1,100;1,300&display=swap');

:root {
    --color_green: #054524;
    --color_gray: #959595;
}

.font-lato {
    font-family: 'Lato', sans-serif;
}

.decoration_green {
    color: var(--color_green);

}

.decoration_gray {
    color: var(--color_green);

}

.input-register {

    @apply w-full my-3 h-[62px] px-3 py-2 border font-lato text-lg font-light border-[#959595];
}

.input-register:focus {
    @apply outline-none border-[#2368A9];
}

.input-review {

    @apply w-full my-3 h-[50px] px-3 py-2 border font-lato text-lg font-light border-[#959595];
}

.input-review:focus {
    @apply outline-none border-[#2368A9];
}

.bigFooter {
    @apply border font-lato text-lg font-light border-[#959595];
}

.bigFooter:focus {
    @apply outline-none border-[#2368A9];
}